import { gql } from '@apollo/client';

export const clientGql = gql`
  query client($id: String!) {
    client(clientId: $id) {
      _id
      slug
      siret
    }
  }
`;
