import './statistic.component.scss';
import { Statistic as StatisticAnt } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { colorCSS } from '../../functions/color.function';
interface Props {
  data: {
    value: number;
  };
  config: {
    precision?: number;
    title?: string;
    way?: boolean;
    suffix?: any;
    explain?: string;
  };
}

const Statistic: React.FC<Props> = ({ config, data }) => {
  const color = {
    error: colorCSS('--color-error'),
    warn: colorCSS('--color-warn'),
    success: colorCSS('--color-success'),
  };

  const defineColor = () => {
    if (
      typeof data?.value === 'number' &&
      data?.value &&
      config?.way &&
      data?.value !== 0
    ) {
      if (Math.sign(data?.value) === 1) {
        return color.success;
      } else {
        return color.error;
      }
    }

    return '';
  };

  const definePrefix = () => {
    if (
      typeof data?.value === 'number' &&
      data?.value &&
      config?.way &&
      data?.value !== 0
    ) {
      if (Math.sign(data?.value) === 1) {
        return <ArrowUpOutlined />;
      } else {
        <ArrowDownOutlined />;
        return;
      }
    }

    return undefined;
  };

  return (
    <div className="statistic component">
      <div className="statistic__contain">
        <StatisticAnt
          title={config?.title}
          value={data?.value}
          precision={config?.precision}
          valueStyle={{
            color: defineColor(),
          }}
          prefix={definePrefix()}
          suffix={config?.suffix}
        ></StatisticAnt>
      </div>
      <span className="statistic__explain">{config?.explain}</span>
    </div>
  );
};

export default Statistic;
