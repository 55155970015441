import './diagramme.component.scss';
import {
  Column as ColumnAnv,
  Bar as BarAnv,
  Pie as PieAnv,
} from '@ant-design/plots';
import { colorCSS } from '../../functions/color.function';

const Diagramme = ({ data: { items }, config }) => {
  const {
    type: { field: fieldType, alias: aliasType },
    value: { field: fieldValue, alias: aliasValue },
    mode,
  } = config;

  const color = {
    graph1: colorCSS('--color-graph1'),
    graph2: colorCSS('--color-graph2'),
    graph3: colorCSS('--color-graph3'),
    graph4: colorCSS('--color-graph4'),
  };

  let options = {
    style: {
      maxHeight: config?.yMax ?? 'initial',
      height: config?.yMax ?? 'initial',
    },
    className: 'diagramme',
    data: items,
  };

  const [a, b, c] = mode.split('.');

  if (a === 'column') {
    options = {
      ...options,
      barWidthRatio: 0.75,
      color: ({ type }) => color.graph1,
      meta: {
        [aliasType]: {
          alias: aliasType,
        },
        [fieldValue]: {
          alias: aliasValue,
        },
      },
    };

    if (c === 'horizontal') {
      options = { ...options, xField: fieldValue, yField: fieldType };
    } else if (c === 'vertical') {
      options = { ...options, xField: fieldType, yField: fieldValue };
    }
  }

  if (a === 'bar') {
    options = {
      ...options,
      xField: fieldValue,
      yField: fieldType,
      seriesField: config?.category,
      isPercent: true,
      isStack: true,
      color: Object.values(color),
      label: {
        position: 'middle',
        content: (item) => {
          return item.value.toFixed(2);
        },
        style: {
          fill: colorCSS('--color-primary'),
        },
      },
    };
  }

  if (a === 'pie') {
    options = {
      ...options,
      appendPadding: 10,
      angleField: fieldValue,
      colorField: fieldType,
      radius: 1,
      color: Object.values(color),
      label: {
        type: 'inner',
        offset: '-30%',
        content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        style: {
          fontSize: 14,
          textAlign: 'center',
        },
      },
      interactions: [
        {
          type: 'pie-legend-active',
        },
        {
          type: 'element-active',
        },
      ],
    };
  }

  switch (a) {
    case 'column' || 'bar':
      options = {
        ...options,
        xField: fieldType,
        yField: fieldValue,
        barWidthRatio: 0.75,
        color: ({ type }) => color.graph1,
        meta: {
          [aliasType]: {
            alias: aliasType,
          },
          [fieldValue]: {
            alias: aliasValue,
          },
        },
      };

      if (c === 'horizontal') {
        options = { ...options, xField: fieldValue, yField: fieldType };
      } else if (c === 'vertical') {
        options = { ...options, xField: fieldType, yField: fieldValue };
      }

      break;
    case 'pie':
      options = {
        ...options,
        appendPadding: 10,
        angleField: fieldValue,
        colorField: fieldType,
        radius: 1,
        color: Object.values(color),
        label: {
          type: 'inner',
          offset: '-30%',
          content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
          style: {
            fontSize: 14,
            textAlign: 'center',
          },
        },
        interactions: [
          {
            type: 'pie-legend-active',
          },
          {
            type: 'element-active',
          },
        ],
      };
      break;
  }

  // const selector = {
  //   'column.basic.vertical': (opt) => <ColumnAnv {...opt} />,
  //   'column.basic.horizontal': (opt) => <ColumnAnv {...opt} />,
  //   'pie.basic.fill': (opt) => <ColumnAnv {...opt} />,
  // };

  // return selector[mode](options);

  switch (mode) {
    case 'column.basic.vertical':
      return <ColumnAnv {...options} />;
    case 'column.basic.horizontal':
      return <BarAnv {...options} />;
    case 'pie.basic.fill':
      return <PieAnv {...options} />;
    case 'bar.basic.horizontal':
      return <BarAnv {...options} />;
  }

  return <span>Choisi le mode</span>;
};

export default Diagramme;
