import { colorCSS } from '../../functions/color.function';
import './badge.component.scss';
import React from 'react';

interface Props {
  config: {
    type: 'down' | 'warn' | 'up';
    text: boolean;
  };
}

const Badge: React.FC<Props> = ({ config: { type, text } }) => {
  const status = {
    down: {
      color: colorCSS('--color-error'),
      text: 'down',
    },
    warn: {
      color: colorCSS('--color-warn'),
      text: 'degraded',
    },
    up: {
      color: colorCSS('--color-success'),
      text: 'up',
    },
  };

  return (
    <div
      className={`badge badge-text-${text ? 'yes' : 'no'}`}
      style={{ backgroundColor: status[type].color }}
    >
      {text && <span>{status[type].text}</span>}
    </div>
  );
};

export default Badge;
