import { assign, createMachine, interpret } from 'xstate';

const initialContext = {
  user: undefined,
  navigate: undefined,
  redirectionTarget: undefined,
};

const setData = (property, value) => {
  if (null === value || undefined === value) {
    localStorage.removeItem(property);
  } else {
    localStorage.setItem(property, JSON.stringify(value));
  }
};

const appMachine = createMachine(
  {
    id: 'app',
    context: initialContext,
    initial: 'loading',
    predictableActionArguments: true,
    states: {
      loading: {
        invoke: {
          id: 'loadingFromStorage',
          src: 'loadFromStorage',
          onDone: {
            target: 'configure',
            actions: [
              assign({
                user: (_, { data }) => data.user,
              }),
            ],
          },
          onError: {
            actions: [
              () => {
                console.log('Error loading from storage');
              },
            ],
          },
        },
      },
      configure: {
        always: [
          {
            cond: 'allIsSet',
            target: 'ready',
          },
        ],
      },
      ready: {
        entry: [
          // Redirection.
          (context) => {
            if (!context.user) context.navigate('/login');
          },
        ],
        on: {
          LOGOUT: {
            actions: [
              assign({
                user: () => undefined,
              }),
              () => setData('user', null),
              (context) => {
                context.navigate('/login');
              },
            ],
          },
          LOGIN_USER: {
            actions: [
              assign({
                user: (_, { username, token }) => ({ username, token }),
              }),
              (_, { username, token }) => setData('user', { username, token }),
              (context) => {
                context.navigate('/onstreet/clients');
              },
            ],
          },
        },
      },
    },
    on: {
      SET_NAVIGATE_CALLBACK: {
        actions: assign({
          navigate: (_, { navigate }) => navigate,
        }),
      },
    },
  },
  {
    guards: {
      allIsSet: (context) => context.navigate !== undefined,
    },
    services: {
      loadFromStorage: async () => {
        let loaded = {};
        for (const property in initialContext) {
          loaded[property] = JSON.parse(localStorage.getItem(property));
        }
        return loaded;
      },
    },
  },
);

export const appService = interpret(appMachine).start();
